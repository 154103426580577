@import '../../variable.less';

#BudgetTracker {
  .empty-budget-tracker {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    padding: 10vh 0 10vh 0;
    text-align: center;

    .empty-title {
      font-size: 1.2rem;
      font-weight: 550;
      padding-top: 20px;
      margin-bottom: 0 !important;
    }

    #createBudgetBtn {
      background-color: #018FFC;
      border: none;
      margin-top: 15px;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .ant-skeleton-title {
    width: 100% !important;
    margin: 0 !important;
  }
  ul.ant-skeleton-paragraph {
    display: none;
  }
}

#CreateBudgetDrawerWrapper {
  padding: 0 33vw;
}