#OnboardingTour {
  position: relative;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  padding: 30px 40px 60px 40px;
  
  @media (max-width: 1440px) {
    padding: 30px 40px 40px 40px;
  }
  @media (max-width: 1440px) {
    padding: 20px 20px 20px 20px;
  }

  .MainContent {
    position: relative;
    margin-top: 100px;
    height: 100%;

    @media (max-width: 780px) {
      margin-top: 50px;
    }
    @media (max-width: 1440px) {
      margin-top: 20px;
    }
    
    .header-content {
      width: 500px;
      margin: auto;

      .ant-typography {
        font-size: 18px;
        line-height: 24px;
        color: #6B6B6B;
      }

      h1.ant-typography {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        color: #222222;
      }
    }

    .content {
      margin-top: 40px;
      padding: 0 150px;

      @media (max-width: 1024px) {
        padding: 0;
      }
      @media (max-width: 1280px) {
        margin-top: 20px;
      }
      @media (max-width: 1440px) {
        padding: 0;
      }

      .card-onboarding-platform {
        width: 100%;
        height: 200px;
        cursor: pointer;
        box-shadow: 4px 4px 20px 0px #8E98A84D;

        @media (max-width: 1024px) {
          height: 290px;
        }
        @media (max-width: 1280px) {
          height: 220px;
        }

        &:hover {
          border-color: #3C41CC;
          transition-duration: 1000ms;
        }
        &.selected-card{
          border-color: #3C41CC;
        }

        .ant-card-body {
          padding: 15px !important;
          
          .onboard-title {
            display: flex;
            justify-content: space-between;

            .logos-title {
              display: flex;
              align-items: center;

              .ant-image {
                margin-right: 15px;
              }

              h3.ant-typography {
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
              }
            }

            .selection {
              .ant-radio-wrapper {
                margin-right: 0
              };
            }

          }

          .description {
            margin-top: 10px;
            text-align: left;
            .ant-typography {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500px;
              color: #6B6B6B;
            }
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-btn {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        height: 40px;
        border-radius: 6px;
        padding: 5px 20px;
      }
      .ant-btn.btn-link-skip {
        color: #6B6B6B;
        padding: 0;
        .span-link {
          margin-left: 5px;
          color: #3C41CC;
        }
      }
    } 
  }
}

#formWebsite {
  width: 450px;
  margin: auto;
  text-align: left;
}

#formMarketplace {
  width: 350px;
  margin: auto;
  
  .card-onboarding-marketplace {
    width: 100%;
    height: 60px;
    cursor: pointer;
    box-shadow: 4px 4px 20px 0px #8E98A84D;

    &:hover {
      border-color: #3C41CC;
      transition-duration: 1000ms;
    }
    &.selected-card{
      border-color: #3C41CC;
    }

    .ant-card-body {
      padding: 15px !important;
      
      .onboard-marketplace {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .ant-image {
          margin-right: 10px;
        }

        h3.ant-typography {
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
        }

      }
    }
  }
}