@import '../../../variable.less';

.sorter-selection {
  .ant-select-focused.sorter-selection {
    border: none !important;
  }
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
  }
}

.binding-toolbar{
  color: @bdd-blue-main;
}

.binding-toolbar:hover{
  color: @bdd-accent;
}

.button-main{
  background-color: @bdd-blue-main !important;
  border: 0 !important;
}

.button-main:hover{
  background-color: @bdd-accent !important;
  border: 0 !important;
}

.list-content-binding-wrapper {
  margin-top: 20px;
  padding: 20px 0;
}

.bindingEmptyContent {
  background-color: @bdd-white;
  border: 1px solid #D0D5DD;
  border-radius: 10px;
  margin: auto;
  width: 100%;  
  padding: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .empty-content-bind-button {
    background-color: #018FFC;
    border: none;
    border-radius: 6px;
    &:hover {
      opacity: 0.85;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;  
  }

  .website{
    background-color: @bdd-blue-main;    
  }
  
  .facebook{
    background-image: linear-gradient(#4274C8,#263B74);
  }

  .instagram {
    background-color:#FFEAFE;
  }
  
  .google-analytics {
    background-color: #F6F6F6;
  }

  .google-ads{
    background-color: #E4FFEC;
  }


  .emptyCard{
    box-sizing: border-box;
    border: solid #EAEAEA 1px;
    border-radius: 15px;
    padding: 20px 0px 10px 0px;
    margin-bottom: 24px;
    width: 400px;

    @media (max-width: 480px) {
      width: 100%;
    }
    
    .emptyCardContent{
      padding: 0px 25px 25px 25px;
      
      .smallImage{         
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background-color: #E7E7E7;
      }

      .largeImage{         
        width: 100%;
        height: 60px;
        border-radius: 8px;
        background-color: #E7E7E7;
        margin-top: 10px;
      }
    }

    .emptyCardFooterContent{
      padding: 10px 25px 0px 25px;
      margin-left: auto;
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      .smallImage{         
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background-color: #E7E7E7;
        margin-left: 10px;
      }
    }
  }


  .emptyCardFooter{
    border-top: solid #EAEAEA 1px;
  }
}

.list-content-binding {
  padding: 10px 0;
  margin-top: 20px;

  .ant-list {
    .ant-spin-container {
      height: 550px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  @media (max-width: 480px) {
    height: 400px;
    padding: 5px;
    margin-top: 10px;

    .ant-card-body{
      padding: 0 10px !important;
    }
  }
}

@media (max-width: 480px) {
  .ant-select {
    width: 100%;
  }
}
