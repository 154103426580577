@import '../../../variable.less';

.account-item{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;


  .tokopedia {
    background-color: @icon-bg-tokopedia;
  }
  .shopee {
    background-color: @icon-bg-shopee;
  }
  .shopify {
    background-color: @icon-bg-shopify;
  }
  .facebook {
    background-color: @icon-bg-facebook-ads;
  }
  .google-analytics {
    background-color: @icon-bg-google-analytic;
  }

  .google-ads {
    background-color: @icon-bg-google-ads;
  }
  .instagram {
    background-color: @icon-bg-instagram;
  }

  .account-item-logo{
    width: max-content;
    height: max-content;
    border-radius: 10px;
  }


  .account-metadata{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    max-width: 200px;

    @media (min-width: 960px) {
      max-width: 100px;
    }
    @media (min-width: 1024px) {
      max-width: 150px;
    }
    @media (min-width: 1400px) {
      max-width: 100px;
    }
    @media (min-width: 1600px) {
      max-width: 200px;
    }

    .account-item-name{
      margin: unset !important;
      font-size: 16px;
      font-weight: 700;
    }
    .account-name-subname{
      margin: unset !important;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .btn-link-delete:hover {
    background-color: #FAC5C1;
  }

  .ant-collapse-header{
    padding:12px 0px !important;
  }

  .ant-collapse-content-box{
    padding: 0 !important;
  }

  .account-action{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .detail-account{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .btn-account-action{
        border-radius: 10px;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .account-share{
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

}
.ant-card-actions {
  background: transparent !important;
  height: calc(100% - 10px);

  li {
    // float: right !important;
    // border: none !important;
    // width: auto !important;
    padding-right: 10px !important;
    text-align: right !important;

    button {
      height: 0 !important;
    }
  }
}
