@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(10em);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
