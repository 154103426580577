@import '../../variable.less';

#CarouselContentPreview {
  background-color: #FFFFFF;
  .slick-arrow {
    display: none !important;
  }
  .slick-slide {
    text-align: center;
    margin: 10px 0 !important;
    > div {
      margin: 0 30px;
    }
  }

  .btn-carousel{
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    padding: 0 5px;
    width: 100%;
    z-index: 9;
    .ant-btn {
      background-color: #018FFC;
      border: none;
      border-radius: 50%;
      color: #FFFFFF;
    }
  }
  .carousel-prev {
    transform: scale(1.2) translateX(-15px);
  }
  .carousel-next {
    transform: scale(1.2) translateX(15px);
  }

  .ant-card {
    // margin-right: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 8%);
    border-color: #D0D5DD;
  }

  .content-preview-card {
    .content-preview-stats-list {
      border: unset;
      .ant-card-head {
        .ant-card-head-wrapper {
          .ant-card-head-title {
            font-weight: bold;
          }
        }
      }
      .ant-card {
        border: unset;
      }
    }
  }

  .ant-card-body{
    padding: 10px;
    text-align: left;
  }

  #carouselMedia {
    height: 560px !important;
  }

  #StatsListWrapper {
    box-shadow: none;
  }

  #StatsItemWrapper {
    box-shadow: none;
  }

  .slick-dots {
    padding-bottom: 20px;
  }
  .slick-dots li {
    transition: none;
    margin: 0 10px;
  }
  .slick-dots li button {
    background: #D0D5DD;
    border: 1px solid @bdd-gray;
    border-radius: 10px;
    line-height: 2px;
    height: 4px;
    transform: scaleY(0.8);
    &::before {
      display: none !important;
    }
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    background: @bdd-blue-main;
  }
  .slick-dots li.slick-active button {
    background: @bdd-blue-main;
    width: 150%;
  }
}