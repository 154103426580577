@import '../../../../variable.less';

#head-menu-item {
  font-size: 14px;
  font-weight: 600;

  p {
    margin: unset;
  }
}
#menu-item {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

#docs-side-menu {
  height: 100vh;
  background-color: @bdd-white;
  // border-right: 2px solid @bdd-border-thin-gray;
  box-shadow: 1px 0px @bdd-border-thin-gray;
  letter-spacing: 0.5px;
  position: fixed;
  z-index: 9;

  .bdd-logo {
    transform: scale(0.5);
  }

  .section-menu {
    margin-top: 30px;
  }

  .ant-menu-item-selected {
    color: @bdd-black;
    background: @bdd-white;
  }

  .menu-active {
    transition: 0.01s ease-in-out;
    color: #000;
    background-color: #fff;
    font-weight: bolder;
    border-left: 5px solid #3C41CC;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: #fff;
  }

  ul.submenu {
    margin-left: 25px;
    border-left: 3px double @bdd-blue-main;

    ul.subsubmenu {
      margin-left: 25px;
      border-left: 3px double @bdd-blue-main;
    }
  }
}
