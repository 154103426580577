// @import '../../variable.less';

#NewTag {
  background-color: #FF683A;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 0.75rem;
  font-weight: 550;
  padding: 2px 4px;
  width: fit-content;
}