// @import '../../variable.less';

#InstagramInstagram {
  .ant-row {
    .ant-col {
      .ant-card {
        height: 100%;
      }
    }
  }
}