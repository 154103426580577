@import '../../variable.less';

#dashboard-layout > .ant-layout-content {
  margin-top: 100px;
}

.toolbar-button{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .bind-facebook-button, .bind-googleAds-button, .bind-googleAnalytics-button {
    border: 0;
    box-shadow: 2px 2px 5px #00358552 !important;
  }

  .bind-instagram-button{
    border: 0;
    border-radius: 10;
    background-color: @bdd-facebook-blue;
    box-shadow: 2px 2px 5px #00358552;
  }

  .bind-instagram-button:hover{
    background-color: @bdd-facebook-blue-alternate !important;
  }

  .bind-marketplace-button{
    background-color: @bdd-blue-main !important;
    border: 0;
    box-shadow: 1px 1px 3px @bdd-smooth-gray;
  }

  .bind-marketplace-button:hover{
    background-color: @bdd-blue !important;
  }

  .bind-website-button {
    background-color: @bdd-blue-main !important;
  }

  .bind-website-button:hover {
    background-color: @bdd-blue !important;
  }

  @media (max-width: 480px) {
    justify-content: center;
  }

  .btn-item{
    margin-left: 10px;
    @media (max-width: 480px) {
      margin: 0 5px;
    }
  }

  .share-button{
    font-weight: bold;
    color: @bdd-blue;
    background-color: #ffff;
    border: solid 2px @bdd-blue;
    box-shadow: 2px 2px 5px #00358552;
    border-radius: 5;
  }

  .share-button:hover{
    color: #fff !important;
    background-color: @bdd-accent !important;
    border-color: @bdd-accent !important;
  }
}

.modal-custom-footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .bind-button{
    background-color: @bdd-blue-main !important;
    border: 0px !important;
    box-shadow: 1px 1px 3px @bdd-smooth-gray;
    .bind-text{
      color: #fff;
    }
  }

  .bind-button:hover{
    background-color: @bdd-accent !important;
  }
}
