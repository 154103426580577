@import '../../variable.less';

#modal-confirm {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  text-align: center;
  padding: 30px 21px 14px;

  h4 {
    font-weight: 600;
    margin: 18px 0px;
  }
  span {
    font-size: @font-size-14;
  }
  .ant-btn {
    height: 48px;
    border-radius: 8px;

    padding: 10px 66px;
    margin-top: 36px;

    font-weight: bold;

    background-color: @bdd-black;
    color: @bdd-white;
    font-size: @font-size-18;

    &:hover,
    &:focus {
      border-color: @bdd-black;
    }
  }
}
