// @import '../../variable.less';

#CustomReport {
  transform: translateY(-30px);
  #EmptyCustomReportList {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    margin-top: 25px;
    min-height: 60vh;
    padding: 10vh 0 10vh 0;
    text-align: center;

    .empty-title {
      font-size: 1.2rem;
      font-weight: 550;
      padding-top: 20px;
      margin-bottom: 0 !important;
    }

    #CreateNewReportBtn {
      background-color: #018FFC;
      border: none;
      margin-top: 15px;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  #FilledCustomReportList {
    min-height: 50vh;
    .loading-container {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .ant-list-pagination {
      margin-top: 0;
    }
    .ant-card {
      box-shadow: 3px 4px 10px 2px #888888;
      cursor: pointer;
      transition: all .3s ease-in-out;
      .ant-card-cover {
        margin-top: 12px;
        // height: 310px;
      }
      .ant-card-body {
        padding: 12px !important;
      }
      &:hover {
        box-shadow: 3px 4px 10px 1px #222222;
        transform: translate(2px, -3px);
      }
    }
  }
}

.create-new-report-popover-options {
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.5;
  padding: 10px;
  &:hover {
    background-color: #F0F2F4;
  }
}

.create-new-report-drawer {
  z-index: 1050 !important;
  .ant-drawer-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }
  .ant-drawer-content {
    background-color: #F0F2F4;
    #CreateNewReportDrawerWrapper {
      padding: 0 33vw;
    }
  }
}

.edit-report-config-popover {
  #ApplyButton {
    font-size: 16px;;
    width: 100%
  }
}