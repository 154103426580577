
#card-image {
  margin-top: 20px;
  border: 1.5px solid #D0D5DD;
  border-radius: 0;

  .ant-card-body {
    .image-content {
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .ant-image {
        width: 100%;
        height: 100%;
      }
    }

    .content-journey {
      padding: 20px;

      .ant-col {
        margin-bottom: 10px;

        h3.ant-typography {
          font-size: 14px;
          color: #000;
        }
        .ant-typography {
          font-size: 14px;
          color: #6B6B6B;
        }
      }

      .numbering {
        background-color: #FF683A;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        margin: auto;
        height: 25px;
        width: 25px;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
      }
    }
  }
}

#menu-table-of-content {
  margin-left: 30px;
  position: fixed;
  .ant-menu {
    background-color: transparent;
  }
  .ant-menu-item {
    padding: unset !important;
    margin: unset !important;
  }
  .ant-menu-item-selected {
    background-color: transparent;
  }
}
