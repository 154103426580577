// @import '../../variable.less';

.account-selection-create-report {
  z-index: 1070 !important;
  .ant-popover-inner-content {
    background-color: #FFFFFF;
    border-radius: 10px;
    max-width: 400px;
  
    #AccountSelectionCreateReport {
      padding: 15px 10px 10px 15px;
      .section-title {
        font-weight: 550;
        margin-bottom: 5px;
      }
      .platform-selection-cols {
        border: 1px solid #D0D5DD;
        border-radius: 6px;
        padding: 3px 5px;
      }
      .account-selection-list-item {
        cursor: pointer;
      }
    }
  }
}