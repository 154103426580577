#PreviewContent {
  width: 100%;
  padding: 10px;

  .content-images {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
  }

  .content-video {
    iframe {
      width: 100%;
      height: 200px;
      border-radius: 8px;
    }
  }

  .content-title {
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 10px;
  }
  
  .content-long-headline {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    margin-top: 10px;
  }
  
  .content-headlines {
    margin-top: 10px;
    width: 100%;
    max-height: 100px;
    
    .ant-typography {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      text-decoration: underline;
      font-style: italic;
    }
  }
  
  .content-descriptions {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-top: 10px;
    width: 100%;
    max-height: 100px;
    color: #9CA0A6;
  }
}