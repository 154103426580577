// @import '../../variable.less';

#chartDrawerTitle {
  margin: auto;
  width: max-content;

  .ant-card-body {
    padding: 20px 30px !important;

    .line-text {
      color: #9CA0A6;
      font-weight: normal;
    }
    .ant-avatar {
      color: black;
    }
  }
}

#chart-bar-wrapper, #chart-combination-wrapper, #chart-donut-wrapper, #chart-line-wrapper, #chart-heatmap-wrapper, #chart-map-wrapper {
  margin: unset !important;
  height: 100%;
  padding-bottom: 10px;
  .ant-card-head {
    padding: 0 16px;
    .title-daterange-current {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    .title-daterange-compare {
      color: #7D8085;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .ant-card-body {
    padding: 0px 10px;
    .ant-collapse {
      padding-bottom: 0px;
      .ant-collapse-item {
        padding: 0px;
        .ant-collapse-header {
          padding: 0px;
          margin-top: 20px;
        }
      }
    }
  }

  #StatsListWrapper {
    border: none;
    .ant-card-head {
      display: none;
    }
    .ant-card-body {
      padding: 0px 10px 10px 10px;
    }
    #StatsItemWrapper {
      padding: 0 10px;
    }
  }
}

#chart-bar-wrapper, #chart-combination-wrapper, #chart-donut-wrapper, #chart-line-wrapper, #chart-heatmap-wrapper {
  .ant-card-extra {
    .chart-display-toggle {
      position: absolute;
      top: 70px;
      right: 25px;
      z-index: 200;
    }
  }
}

#chart-donut-wrapper {
  .ant-list-header {
    background-color: #F0F2F4;
  }
  .ant-list-items {
    max-height: 40vh;
    overflow-y: scroll;
  }
  .ant-list-item {
    background-color: #FFFFFF;
    display: block;
    .ant-col {
      text-align: left;
    }
  }
  .custom-report-chart-donut-legends-space {
    visibility: visible;
  }
}

#chart-map-wrapper {
  .highcharts-legend {
    transform: translateX(60%) translateY(88%);
  }
}

.ant-drawer-body {
  overflow-x: hidden !important;
  #chart-donut-wrapper {
    background-color: transparent;
    .ant-card-head {
      margin-bottom: 30px;
    }
  }
}
