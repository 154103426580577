@import './variable.less';

@font-face {
  font-family: Satoshi;
  src: url('./assets-v1/fonts/Satoshi-Regular.ttf');
}

@font-face {
  font-family: Satoshi;
  src: url('./assets-v1/fonts/Satoshi-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Satoshi;
  src: url('./assets-v1/fonts/Satoshi-Medium.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Satoshi;
  src: url('./assets-v1/fonts/Satoshi-Italic.ttf');
  font-style: italic;
}

@primary-color: @bdd-blue; // primary color for all components
@link-color: @bdd-blue; // link color
@success-color: @bdd-blue; // success state color
@warning-color: @bdd-gray; // warning state color
@error-color: @bdd-danger; // error state color
@font-size-base: 16px; // major text font size
@heading-color: @bdd-black; // heading text color
@text-color: @bdd-black; // major text color
@text-color-secondary: @bdd-gray; // secondary text color
@disabled-color: @bdd-gray; // disable state color
@border-radius-base: 4px; // major border radius
@border-color-base: @bdd-border; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

@font-family: Satoshi;

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

h1.ant-typography {
  font-size: @font-size-32;
  line-height: 48px;
  margin: 0;
  font-weight: bold;
}

h2.ant-typography {
  font-size: @font-size-21;
  line-height: 31.5px;
  font-weight: bold;
  margin: 0 !important;
}

h3.ant-typography {
  font-size: @font-size-18;
  line-height: 27px;
  font-weight: bold;
  margin: 0px !important;
}

h4.ant-typography {
  font-size: @font-size-16;
  font-weight: 400;
  margin: 0;
}

h5.ant-typography {
  font-size: @font-size-14;
  font-weight: 400;
  margin: 0;
}

.text-success {
  color: @bdd-success !important;
}

.text-warning {
  color: @bdd-warning !important;
}

.text-danger {
  color: @bdd-danger !important;
}

.ant-spin {
  color: #018FFC;
}

.ant-card-body {
  padding: 15px;
  width: 100%;
}

.ant-tooltip {
  font-size: @font-size-10;
  z-index: 10000;

  .ant-tooltip-inner {
    padding: 4px;
    min-height: 0;
  }
}

.ant-select-item {
  font-size: @font-size-14;
}

.ant-card {
  // box-shadow: 0 5px 15px #14006314;
  border-radius: 10px;
  margin-bottom: 24px;

  &.no-bottom {
    .ant-card-body {
      padding-bottom: 0px;

      #card-data,
      #card-data-loading {
        border: solid 0.5px #ccc;
      }
    }

    @media (max-device-width: 480px) {
      margin: 24px -16px;
      border-radius: 0px;
    }
  }

  .ant-card-head {
    padding: 16px;

    .ant-card-head-title {
      padding: 0px;
      font-size: @font-size-16;
    }
  }

  .ant-card-body {
    padding: 16px;
    padding-top: 0px;
  }
}

.ant-checkbox-inner {
  border-color: @bdd-blue !important;
}

.ant-tabs-tab {
  font-size: 14px;
  color: #9CA0A6;
}
.ant-tabs-tab-active {
  font-weight: 700;
}

.ant-tabs-card {
  @media (max-device-width: 480px) {
    margin: 0px -16px;

    .ant-tabs-nav {
      margin-bottom: 0px;

      &::before {
        border: none;
      }

      .ant-tabs-tab {
        border: none;
        background-color: transparent;
        padding: 8px 12px;

        margin: 0px 0px 8px 16px;

        .ant-tabs-tab-btn {
          color: @bdd-black;
          font-size: @font-size-12;
          line-height: 14px;
        }

        &.ant-tabs-tab-active {
          background-color: @bdd-white;
          border-radius: 5px;
        }
      }
    }

    .ant-tabs-content-holder {
      background-color: @bdd-white;
      padding: 16px;

      border-radius: 0px;

      min-height: calc(100vh - 234px);
    }
  }

  @media (min-device-width: 480px) {
    .ant-tabs-nav {
      margin-bottom: -16px;

      &::before {
        border: none;
      }

      .ant-tabs-tab {
        border: none;
        background-color: transparent;
        padding: 10px 24px 26px;

        .ant-tabs-tab-btn {
          color: @bdd-black;
          font-size: @font-size-12;
          line-height: 14px;
        }

        &.ant-tabs-tab-active {
          background-color: @bdd-white;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
    }
    .ant-tabs-content-holder {
      background-color: @bdd-white;
      padding: 40px 24px 24px;

      border-radius: 5px;

      min-height: calc(100vh - 234px);
    }
  }
}

.unbind {
  flex-direction: column;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 240px;

    .unbind-text {
      text-align: center;
    }

    .ant-btn {
      margin-top: 32px;
    }
  }

  .unbind-text {
    font-size: @font-size-14;
    line-height: 21px;

    color: @bdd-grey-disabled;

    margin: 2px 0px 8px;
  }

  .ant-btn {
    height: 32px;
    color: @bdd-blue;
    border-color: @bdd-blue;

    font-size: @font-size-14;

    margin-bottom: 10px;
    max-width: 184px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;

      width: 100%;
    }
  }
}

// .ant-notification {
//
//   .ant-notification-notice {
//     background-color: #FFDACE;
//     border-radius: 20px;
//   }
//
//   .ant-notification-notice-icon {
//     color: black;
//   }
//
//   .ant-notification-close-icon {
//     color: black;
//   }
//
// }

.slide-pane__overlay {
  z-index: 9;
}
.sidebar-mobile {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  max-height: 100vh;
  padding: 0px;

  .slide-pane__content {
    padding: 0px;
    max-height: 100vh;
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(8px);
  }

  .ant-modal-wrap {
    overflow: auto;
    transform: scale(1, 0.95);

    .ant-modal {
      .ant-modal-content {
        border-radius: 15px;
        .ant-modal-header {
          border-radius: 15px 15px 0 0;
        }
        .ant-modal-body {
          padding: 20px 24px 24px 24px;
        }
      }
    }

    .modal-card {
      height: 90% !important;
      width: 90% !important;
      transform: translateY(-2.5vh);

      .ant-modal-header {
        border-radius: 5px 5px 0 0;

        .ant-modal-title {
          .format-header-modal-expands {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title-description {
              width: fit-content;
              margin: auto;
              text-align: center;
            }

            .action-button {
              position: absolute;
              top: 30px;
              right: -50px;
              transform: scale(1.1, 1.1);

              .close-modal-card {
                cursor: pointer;
                padding: 4px;
                background: #fff;
                height: 35px;
                width: 35px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

      }
      .ant-modal-close {
        display: none;
      }
    }
  }
}

.ant-popover-inner-content {
  border-color: white;
}

.modal-is-compared.modal-card {
  width: 50% !important;
  transform: translateY(2vh);

  @media (max-device-width: 1400px) {
    width: 70% !important;
  }

  @media (max-device-width: 480px) {
    width: 90% !important;
  }
}

.modal-no-compared.modal-card {
  width: 30% !important;
  transform: translateY(2vh);

  @media (max-device-width: 480px) {
    width: 90% !important;
  }
}

.btn-popup-detail {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 100%;

  .ant-image {
    padding: unset;
  }
}

.loading {
  background: #e5e5e5;
  background-image: linear-gradient(
    to right,
    #e5e5e5 0%,
    #f6f7f8 20%,
    #e5e5e5 40%,
    #e5e5e5 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 800px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-disableInteraction {
  border: none;
  box-shadow: none;
}
.introjs-helperLayer {
  border-radius: 10px !important;
  box-shadow: 0 0 1px 2000px rgba(0, 0, 0, 0.6) !important;
}

.introjs-tooltip {
  max-width: 350px !important;
}
.intro-guide, .coachmark {
  border-radius: 5px;
  padding: 16px;
  width: 700px;
  border: none !important;

  @media (max-device-width: 950px) {
    width: 200px;
  }

  &.introjs-right {
    top: 20px !important;
  }

  &.introjs-bottom-middle-aligned {
    left: -125px !important;
    // @media (max-device-width: 950px) {
    //   left: -10px !important;
    // }
  }

  .introjs-tooltip-header {
    padding: 0px;
    .introjs-tooltip-title {
      font-size: 16px;
      line-height: normal;
      width: 100%;
    }
    // .introjs-skipbutton {
    //   display: none;
    // }
  }

  .introjs-tooltiptext {
    padding: 0px;
    margin-top: 12px;
    font-size: 12px;
  }

  .introjs-tooltipbuttons {
    padding: 0px;
    margin-top: 30px;
    border: none;
    float: right;

    .introjs-button {
      border-radius: 5px;
      border: none;
      font-size: 14px;

      color: @bdd-white;
      background-color: @bdd-primary;

      text-shadow: none;

      &.introjs-disabled {
        background: #d4d4d4 !important;
        color: #999999 !important;
        // border: 1px solid #999999 !important;
      }
      &.introjs-prevbutton {
        display: none;
        float: left;
        margin-right: 10px;
        color: #018FFC;
        background-color: #FFFFFF;
        border: 1px solid #018FFC;
      }
      &.introjs-nextbutton {
        float: right;
        color: #FFFFFF;
        background-color: #018FFC;
      }
      &.introjs-donebutton {
        float: right;
        color: #FFFFFF;
        background-color: #018FFC;
      }
    }

  }
  
  .introjs-helperNumberLayer {
    display: none;
  }

  .introjs-arrow {

    &.left {
      top: 40px;
    }
    &.bottom {
      left: 40px;
    }
    &.top {
      left: 40px;
    }
    &.right {
      top: 20px;
    }
  }
}

.ant-input {
  border-radius: 10px;
  padding: 7px 12px !important;
  font-size: 14px !important;
}
.ant-input-affix-wrapper {
  border-radius: 10px;
  padding: 0 12px 0 0;
}

.ant-btn {
  border-radius: 10px;
}
.ant-typography {
  margin-bottom: 5px !important;
}

@media only screen
and (orientation: portrait)
and(max-device-width: 480px) {
  .ant-typography {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .ant-input {
    font-size: 14px !important;
  }
  .ant-input-search-button {
    height: 30px;
  }

  .ant-btn {
    font-size: 14px;
  }

  h1.ant-typography {
    font-size: @font-size-24 !important;
    line-height: normal !important;
    margin-bottom: 10px !important;
    font-weight: bold !important;
  }

  h2.ant-typography {
    font-size: @font-size-18 !important;
    line-height: normal !important;
    font-weight: bold;
    margin: 0 !important;
  }

  h3.ant-typography {
    font-size: @font-size-14 !important;
    line-height: normal !important;
    font-weight: bold !important;
    margin: 0px !important;
  }

  h4.ant-typography {
    font-size: @font-size-12 !important;
    font-weight: 400 !important;
    margin: 0 !important;
  }

  h5.ant-typography {
    font-size: @font-size-10 !important;
    font-weight: 400 !important;
    margin: 0 !important;
  }

  .ant-card-body {
    padding: 10px !important;
  }

  .ant-tabs-tab {
    font-size: 14px !important;
  }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  .ant-typography {
    font-size: 14px !important;
    line-height: normal !important;
  }

  .ant-input {
    font-size: 14px !important;
  }
  .ant-input-search-button {
    height: 30px;
  }

  .ant-btn {
    font-size: 14px;
  }

  h1.ant-typography {
    font-size: @font-size-24 !important;
    line-height: normal !important;
    margin: 0 !important;
    font-weight: bold !important;
  }

  h2.ant-typography {
    font-size: @font-size-18 !important;
    line-height: normal !important;
    font-weight: bold;
    margin: 0 !important;
  }

  h3.ant-typography {
    font-size: @font-size-14 !important;
    line-height: normal !important;
    font-weight: bold !important;
    margin: 0px !important;
  }

  h4.ant-typography {
    font-size: @font-size-12 !important;
    font-weight: 400 !important;
    margin: 0 !important;
  }

  h5.ant-typography {
    font-size: @font-size-10 !important;
    font-weight: 400 !important;
    margin: 0 !important;
  }

  .ant-card-body {
    padding: 10px !important;
  }

  .ant-tabs-tab {
    font-size: 14px !important;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  font-weight: normal;
}

th.ant-table-cell {
  font-size: 14px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.ant-table-tbody > tr {
  font-size: 12px !important;
}


::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #D0D5DD;
}
