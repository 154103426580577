.OnboardingPopUp {
  .ant-modal-content {
    border-radius: 10px !important;
    .ant-modal-body {
      padding: 0 !important;
      height: 700px;

      .ant-carousel {
        padding-bottom: 30px;
        .slick-slider {
          .slick-list {
            .slick-track {
              width: 10000px !important;
              .slick-slide {
                margin: 0;
              }
            }
          }

          .slick-dots {
            li {
              background-color: #D0D5DD;
              width: 8px;
              height: 8px;
              border-radius: 50px;
              
              button {
                background-color: #D0D5DD;
                width: 8px;
                height: 8px;
                border-radius: 50px;
                pointer-events: none;
              }
            }
            li.slick-active {
              width: 16px;
              height: 8px;
              border-radius: 50px;
              button {
                pointer-events: none;
                width: 16px;
                height: 8px;
                background-color: #018FFC;
                border-radius: 50px;
              }
            }
          }
        }
        
        .slick-dots-bottom {
          bottom: -70px;
        }
      }

      .content-onboarding {
        border-radius: 10px;
        .content-img {
          height: 420px;
          border-radius: 10px 10px 0 0;
          background-color: #fff;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .content-text {
          text-align: center;
          padding: 25px 40px 40px 40px;

          h1.ant-typography {
            font-size: 24px;
            color: #000;
            margin-bottom: 10px !important;
          }

          .ant-typography {
            font-size: 18px;
            color: #6B6B6B;
            margin-bottom: 15px !important;
          }
        }
      }

      .button-navigation {
        position: absolute;
        bottom: 60px;
        width: 100%;

        .ant-btn {
          width: 140px;
          height: 40px;
          border-radius: 6px;
          padding: 5px 20px;
          font-size: 16px;
          font-weight: 700;
        }
        .ant-btn.default-button {
          width: auto;
        }
        .ant-btn.ant-btn-link {
          width: auto;
          height: 40px;
          border-radius: 6px;
          span {
            text-decoration: underline;
          }
        }

        .ant-btn.btn-back {
          width: 100px;
          height: 40px;
          border-radius: 6px;
          color: #9CA0A6;
        }
        .ant-btn.btn-next {
          width: 100px;
          height: 40px;
          border-radius: 6px;
        }

        .ant-space {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}