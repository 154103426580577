@import '../../../variable.less';

.ant-notification {
  .ant-notification-notice-error {
    background-color: #FEECEB;
    border: 1px solid #F5827A;
    border-left: 4px solid #F5827A;
  }
  .ant-notification-notice-info {
    background-color: #E6F4FF;
    border: 1px solid #55B4FD;
    border-left: 4px solid #55B4FD;
  }
  .ant-notification-notice-success {
    background-color: #EEF8F2;
    border: 1px solid #90D2A8;
    border-left: 4px solid #90D2A8;
  }
  .ant-notification-notice-warning {
    background-color: #FEF8E6;
    border: 1px solid #F8D354;
    border-left: 4px solid #F8D354;
  }

  .ant-notification-notice-close-x {
    svg {
      transform: scale(1.3);
    }
  }
}