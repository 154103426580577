// @import '../../variable.less';

#ApplyButton {
  background-color: #018FFC;
  border: none;
  font-size: 14px;
  &:hover {
    opacity: 0.85;
  }
}

#CopyToClipboardButton {
  background-color: #018FFC;
  border: none;
  font-size: 14px;
  &:hover {
    opacity: 0.85;
  }
}

#ExportButton {
  svg {
    transform: translateY(3px);
  }
}

#FilterButton {
  background-color: #F0F2F4;
  border: 1px solid #BBC0C7;
  font-size: 14px;
  &:hover {
    opacity: 0.85;
  }
}

#SeeDetailButton {
  border: 1px solid #018FFC;
  border-radius: 6px;
  color: #018FFC;
  font-size: 14px;
  &:hover {
    opacity: 0.85;
  }
}

#ShareButton {
  color: #7D8085;
  font-weight: 500 !important;
  svg {
    transform: translateY(3px);
  }
}

.btn-disabled {
  background-color: #F0F2F4 !important;
}