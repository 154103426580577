@import '../../variable.less';

// #login-page {
//   .form {
//     .ant-form-item-extra {
//       display: flex;
//       justify-content: flex-end;
// 
//       .btn-forgot {
//         margin-top: 8px;
//       }
//     }
//   }
// }

#landing-user-page {
  font-size: 14px;
  .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item-label {
      padding-bottom: 4px;
    }
    .ant-form-item, .ant-form-item-required {
      font-size: 14px;
    }
    .ant-form-item-required::before {
      display: none;
    }
    .ant-input {
      border-radius: 8px;
    }
    .ant-input-password-icon {
      color: #222222;
    }
  }
  .ant-input-group-addon {
    background-color: #FFFFFF;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-select-focused {
    border: none;
    box-shadow: none;
    .ant-select-selector {
      border: none;
      box-shadow: none;
    }
  }
  .ant-select-selector {
    border: none;
    border-radius: 20px;
    padding: 0 4px;
    // z-index: 100;
  }
  .ant-select-selection-item {
    border: none;
    color: #3C41CC;
    // padding-right: 3px;
  }
  #login_phone {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 1px !important;
  };
  .btn-forgot {
    color: #018FFC;
    font-size: 14px;
  }
  .footer-link-btn {
    padding: 0;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress {
    width: 100%;
    .ant-progress-steps-item {
      height: 4px !important;
      min-width: 24%;
      margin-right: 1%;
    }
  }
}

.password-progress-popover-content {
  .ant-popover-inner-content {
    padding: 10px !important;
    width: 500px;
    .ant-progress-text {
      display: none;
    }
    .ant-progress {
      width: 100%;
      .ant-progress-steps-item {
        height: 4px !important;
        min-width: 24%;
        margin-right: 1%;
      }
    }
  }
}