@import '../../variable.less';

#header-help-landing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 30px;
  height: 80px;
  position: fixed;
  width: 100%;
}

#help-layout-landing {
  background-color: #F0F2F4;
  margin-top: 80px;
  min-height: 90vh;

  .onboard-banner {
    height: 150px;
    padding: 20px 250px;
    background-color: #3C41CC;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    h1.ant-typography {
      font-size: 24px;
      color: #fff;
    }
  }

  .content-board {
    padding: 30px 250px;

    h2.ant-typography {
      font-size: 20px;
    }
    .ant-typography {
      font-size: 16px;
    }

    .button-whatsapp {
      width: 200px;
      height: 50px;
      font-size: 16px;
      font-weight: 700;
      border-radius: 50px;
      color: #fff;
      background-color: #59BC7D;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#card-list-onboard {
  margin-top: 30px;
  .ant-card-body {
    .item-doc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      .btn-link-doc {
        color: #000;
        margin-top: 10px;
      }
    }
  }
}
