@import '../../../variable.less';

#rangePickerWrapper {
  background-color: @bdd-white;
  border: 1px solid gray;
  padding: 25px;
  // text-align: center;

  .date-range-picker-current {
    .rdrDay {
      .rdrStartEdge, .rdrInRange, .rdrEndEdge {
        background-color: @bdd-blue-main;
      }
    }
    .rdrDayHovered {
      .rdrDayStartPreview, .rdrDateEndPreview {
        border-color: @bdd-blue-main-light;
      }
    }
  }

  .date-range-picker-compare {
    .rdrDay {
      .rdrStartEdge, .rdrInRange, .rdrEndEdge {
        background-color: @bdd-orange-main;
      }
    }
    .rdrDayHovered {
      .rdrDayStartPreview, .rdrDateEndPreview {
        border-color: @bdd-orange-main-light;
      }
    }
  }

  .rdrInputRanges {
    display: none;
  }
}
.ant-popover-inner {
  border-radius: 15px !important;
  background-color: white;
}
.ant-radio-wrapper {
  font-size: 14px !important;
}
.ant-popover-inner-content {
  padding: 0 !important;
  border-radius: 15px;
  border: unset !important;

  #rangePickerPopoverContent {
    min-width: 320px;
    margin: 0 !important;
    padding-bottom: 10px;
    border-radius: 15px;

    .ant-card-body {
      padding: 0 0 20px 0 !important;

      .popover-inner-content {
        padding-left: 15px;
        padding-right: 15px;
      }

      #rangePickerTitle {
        padding-top: 10px;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .ant-switch.ant-switch-checked {
        background-color: #59BC7D;
      }

      .compare-selection {
        background-color: #F0F2F4;
        margin: 10px 0 10px 0;
        padding: 10px 0;
      }

      .range-picker-identifier {
        color: #9CA0A6;
        font-size: 0.8rem;
      }

      .ant-picker-range {
        background-color: #F0F2F4;
        padding-left: 40px;
        margin-bottom: 10px;
        width: 100%;
      }

      .ant-picker-suffix {
        position: absolute;
        left: 5px;
      }

      .breakdown-interval-prefix {
        color: #9CA0A6;
        font-size: 0.8rem;
      }

      .ant-select-selector {
        background-color: #F0F2F4;
      }

      #applyButton {
        float: right;
        font-size: 0.9rem;
        width: 100px;
      }
    }
  }
}

.ant-picker-cell-inner {
  // height: 30px !important;
  // width: 30px !important;
  // line-height: 30px !important;
  border-radius: 50% !important;
}
.ant-picker-cell-inner::before {
  border-radius: 50% !important;
}

.separator-compare {
  border-left: 2px solid #A6AAB1;
  border-top: 2px solid #A6AAB1;
  border-bottom: 2px solid #A6AAB1;
  border-radius: 6px !important;
  height: 65px;
  width: 25px;
  border-radius: 0px;
  position: absolute;
  top: 35px;
  left: 6px;
}
.current-range-picker, .compare-range-picker {
  z-index: 1;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

// .ant-picker-panels > *:last-child {
//   display: none !important;
// }

.ant-picker-panel-container, .ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
