@import '../../../variable.less';
#header-help-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3C41CC;
  padding: 10px 30px;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 9;
  .help-header {
    .ant-typography {
      color: #fff;
    }
  }
  .user-info {
    .ant-typography {
      color: #fff;
    }
    .anticon {
      color: #fff;
    }
  }
}

#landing-docs {
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  letter-spacing: 0.5px;

  .content-desktop {
    padding: 30px 60px;
    margin-left: 300px;
  }
  .content-tablet {
    padding: 30px 60px;
    margin-left: 300px;
  }
  .content-mobile {
    padding: 0 0;
    transform: translateY(-20px);
  }

  header.docs-header {
    padding: 0 2.5vw;
    background-color: @bdd-white;
    border-bottom: 2px solid @bdd-border-thin-gray;

    .header-left {
      font-size: 18px;
      font-weight: 550;

      .dir-title {
        cursor: pointer;
        color: @bdd-blue-main;
        transition: 0.3s ease-in-out;
      }

      .dir-title:hover {
        color: @bdd-accent;
      }
    }

    .header-right {
      float: right;

      .contact-us-text {
        cursor: pointer;
        color: @bdd-blue-main;
        transition: 0.3s ease-in-out;
      }
      .contact-us-text:hover {
        opacity: 0.85;
      }
    }
  }

  .docs-content {
    padding: 30px 60px; // top-right-bottom-left
    background-color: #F0F2F4;

    .section-title {
      text-align: center;
    }
    li {
      margin-bottom: 0.75rem;
    }
    div.img {
      text-align: center;
      margin: 30px 0;
    }

    em {
      font-weight: 600;
    }

    .binding-steps,
    .common-ui-item-img,
    .dashboard-features-img {
      border: 1px solid @bdd-border-thin-gray;
      // border-radius: 10px;
      box-shadow: 3px 3px 10px @bdd-border-thin-gray;
    }
  }

  .footer {
    text-align: right;
    font-size: 10px;
    margin: 5px 0;
  }

  // ::-webkit-scrollbar {
  //   transform: translateX(-10px);
  //   width: 20px;
  // }
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px grey;
  //   border-radius: 0px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   // border: 4px solid rgba(0, 0, 0, 0);
  //   // background-clip: padding-box;
  //   // background: @bdd-background-lightgray2;
  //   background: @bdd-navy ;
  //   border-radius: 5px;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: @bdd-navy ;
  // }
}
