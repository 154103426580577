// @import '../../variable.less';

#PlatformFilter {
  .anticon-close-circle {
    background-color: #F8F8F8;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 10px;
    transform: scale(1.3);
    transition: all .3s ease-in-out;
    &:hover {
      color: #018FFC;
      // background-color: #018FFC;
    }
  }
  .platform-filter-btn-applied {
    background-color: #99d3ff;
  }
}

#filterContent {
  font-size: 0.9rem;
  margin: 0;
  width: 260px;
  padding: 15px;

  .ant-select-selector {
    background-color: #FAFBFC;
    font-size: 14px;
  }

  .section-title:nth-child(1) {
    margin-top: 0;
  }

  .section-title {
    margin: 10px 0;
  }

  .ant-divider {
    margin: 10px 0;
  }
}

.ant-tooltip-inner {
  font-size: 12px;
  letter-spacing: 1px;
}