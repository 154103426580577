@import '../../variable.less';

#DashboardInstagram {
  #tabbedTablesWrapper {
    .ant-card-head {
      display: none;
    }
  }
  .empty-content-instagram {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    height: 50vh;
    text-align: center;
    padding-top: 10vh;
    .bind-button {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  #StatsListWrapper {
    .ant-card-head {
      padding: 10px 16px !important;
    }
  }
  .single-chart {
    .title-daterange-current, .title-daterange-compare {
      display: none;
    }
  }

  .ant-tabs {
    .ant-card-extra {
      padding: 0;
    }
  }
  .card-chart-heatmap {
    .highcharts-legend-item {
      display: none;
    }
  }

  #TopRegionsSection {
    #rc-tabs-0-panel-0 {
      .ant-collapse {
        display: none;
      }
    }
  }
}

