@import '../../../variable.less';

#CreateBudgeting {
  text-align: center;
  .main-title {
    font-size: 1.2rem;
    font-weight: 550;
  }

  .panel-wrapper {
    background-color: #FFFFFF;
    border: 1p solid #D0D5DD;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    .panel-title {
      font-weight: 550;
      .number-avatar {
        background-color: #3C41CC;
        margin-right: 10px;
      }
      .check-step-wrapper {
        margin-right: 10px;
      }
      .step-copy {
        font-weight: 700;
      }
    }
    .panel-btn {
      margin-top: 20px;
      text-align: center;
    }
  }

  .empty-budget-selected-accounts {
    text-align: center;
    .empty-title {
      font-weight: 550;
      margin-top: 10px;
    }
  }

  .selected-account-item-card {
    padding: 10px;
  }
  
  #addPlatformAndAccountBtn {
    border-color: #018FFC;
    color: #018FFC;
    &:hover {
      opacity: 0.75;
    }
  }

  #BrandNamePanel, #DaterangePanel, #AccountSelectionPanel {
    text-align: left;
  }

  .ant-picker {
    border-radius: 10px;
    width: 100%;
  }

  .ant-picker.ant-picker-range.ant-picker-focused {
    .ant-picker-input:first-child {
      input {
        text-align: right;
        padding-right: 10px;
      }
    }
    .ant-picker-input {
      padding-left: 10px;
    }
  }

  .input-step-one-complete {
    background-color: #E9E9E9;
    .ant-input {
      background-color: #E9E9E9;
    }
  }

  .selected-account-budget-input {
    background-color: #F8F9FA;
    .ant-input {
      background-color: #F8F9FA;
    }
  }

  .check-accounts-again-text {
    color: #9CA0A6;
    text-align: center;
  }

  #createBudgetBtn {
    margin: 20px 0 50px 0;
    width: 100%;
  }
}

.budget-range-picker-popover {
  .ant-picker-panel-container, .ant-picker-footer {
    width: 560px !important;
  }
}