// @import '../../variable.less';

#accountSelectionBtn {
  background-color: #FAFBFC;
  border: 1px solid #BBC0C7;
  border-radius: 7px;
  color: #018FFC;
  font-size: 14px;
  display: flex;
  align-items: center;

  .state-text-account {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    text-align: left;
  }

  &:hover {
    opacity: 0.85;
  }
}

.ant-list.ant-list-split {
  border-radius: 10px;
}

.account-selection-modal {
  .ant-modal-header {
    border: none !important;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-list.ant-list-split {
    padding-left: 15px;
    padding-right: 0;
  }
  .plus-icon-add-account {
    padding-right: 5px;
    vertical-align: top;
  }
  .ant-select-selector {
    background-color: #F0F2F4 !important;
    border: 1px solid #BBC0C7 !important;
    box-shadow: none !important;
    width: 175px !important;
  }
}
