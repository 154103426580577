@import '../../variable.less';

#privacy-policy {
  padding: 10em 10em 3em 10em;
  line-height: 2em;
  background-color: @bdd-white;

  h1 {
    color: @bdd-navy;
    font-size: 4em;
    font-weight: 600;
    text-align: center;
  }

  .abstract {
    text-align: center;
    margin-top: 4em;
    margin-bottom: 5em;
  }

  h2 {
    font-size: 2em;
    font-weight: 600;
  }

  p {
    color: @bdd-smooth-gray;
    text-align: justify;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 3em;
  }
}