@import '../../variable.less';

@filledColHeight: 135px;

#summary{

  .empty-content {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    // border-radius: 10px;
    margin-top: 25px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    // position: absolute;
    // top: 35px;
    // right: 16px;
    // bottom: 0;
    // left: 16px;
    // min-height: max-content;

    .line-1 {
      font-size: 1.3rem;
      font-weight: 550;
    }

    .bind-button {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }

    &.meta {
      // height: calc(@filledColHeight*2);
      height: auto;
    }

    &.instagram {
      // height: calc(@filledColHeight*1);
      height: auto;
    }

    &.tiktok, &.googleAds, &.googleAnalytics {
      // height: calc(@filledColHeight*3);
      height: auto;
    }

    &.marketplace, &.website {
      // height: calc(@filledColHeight*1);
      height: auto;
    }
  };

  .filled-content {
    border-radius: 10px;
    margin-top: 20px;

    .filled-col {
      background-color: #FFFFFF;
      border: 1px solid #D0D5DD;
      padding: 20px 5px 5px 20px;
      min-height: @filledColHeight;

      .title-metric {
        font-size: 16px;
        font-weight: 600;
      }

      .summary-current-value {
        font-size: 24px;
        font-weight: 700;
      }
      .summary-compared-value {
        font-size: 14px;
        font-weight: 600;
        color: #54575c;
      }
      .summary-percentage-value {
        font-size: 14px;
        font-weight: 600;
        color: #54575c;
      }

      .highcharts-container {
        transform: scale(1.6);
        background-color: transparent;
      }
    }

    &.meta {
      .chart-legend {
        text-align: center;
        position: absolute !important;
        top: 0;
        right: 0;
        // transform: translateY(-2vh);
      }
    }
    &.tiktok, &.googleAds {
      height: calc(@filledColHeight * 3.25);
    }
    &.marketplace, &.website {
      height: auto;
    }
    &.googleAnalytics {
      height: auto;
    }

    .radius-top {
      border-radius: 10px 10px 0 0;
    }
    .radius-bottom {
      border-radius: 0 0 10px 10px;
    }
    .radius-left {
      border-radius: 10px 0 0 10px;
    }
    .radius-right {
      border-radius: 0 10px 10px 0;
    }
    .radius-top-left {
      border-radius: 10px 0 0 0;
    }
    .radius-top-right {
      border-radius: 0 10px 0 0;
    }
    .radius-bottom-left {
      border-radius: 0 0 0 10px;
    }
    .radius-bottom-right {
      border-radius: 0 0 10px 0;
    }

    // .plain-chart-donut {
    //   position: absolute;
    //   // top: 10%;
    // }
  }

  .platform-title-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    .platform-title {
      display: flex;
      align-items: center;
      
      .ant-typography {
        margin-left: 10px;
        margin-bottom: unset !important;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  // #googleAdsPanel {
  //   transform: translateY(-9px);
  // }

  #marketplacePanel {
    margin-top: 20px;
  }
  @media (max-width: 1200px) {
    #marketplacePanel {
      margin-top: 0;
    }
  }



  .ant-card-loading-content {
    padding: 20px;
  }

  .alert-warning-account {
    background-color: #fffbe6 !important;
    border: 1px solid #ffe58f !important;
  }

  .ant-alert-warning .ant-alert-icon {
    color: #faad14 !important;
  }

  .ant-progress-bg {
    background-color: @bdd-blue-main;
  }
}

@media only screen
and (orientation: portrait)
and(max-device-width: 480px) {
  #summary{
    .sumWeb {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .sumGA {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .sumGAd {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
  }
}
@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #summary{
    .sumWeb {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .sumGA {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .sumGAd {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
  }
}

#card-content-mobile {
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  .ant-card-head {
    background-color: #F8F9FA;
    
    .platform-title-summary {
      display: block;
      .platform-title {
        width: 100%;
      }
    }
    #accountSelectionBtn {
      border: unset;
      background-color: transparent;
      width: 100%;
      justify-content: space-between;
    }
  }
  .ant-card-body {
    padding: 0 !important;

    .filled-content {
      width: 100%;
      margin-top: unset;

      .filled-col {
        width: 100%;
        background-color: transparent;
        border-radius: 0 !important;
        border-top: 1px solid #D0D5DD;
        border-left: unset;
        border-right: unset;
        border-bottom: unset;
      }
    }
  }
}