// @import '../../variable.less';

#CustomReportWorkspaceLayout {
  #CustomReportWorkspaceHeader {
    background-color: #FFFFFF;
    padding: 0 50px 0 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    #ReportName {
      cursor: pointer;
      font-size: 20px;
      .ant-input {
        font-size: 20px;
      }
    }
    .anticon-arrow-left, .state-icon {
      cursor: pointer;
      &:hover {
        color: #3C41CC;
        path {
          stroke: #3C41CC;
        }
      }
    }
    .state-icon {
      transform: translateY(10px);
    }
    .ant-divider-vertical {
      border-left: 1px solid #D0D5DD;
      height: 1.5em;
      margin: 0 12px;
    }
  }
  #CustomReportWorkspaceSubheader {
    background-color: #F8F9FA;
    height: 54px;
    line-height: 54px;
    padding: 0 40px 0 40px;
    position: sticky;
    top: 64px;
    left: 0;
    z-index: 10;
    .ant-divider {
      border-left: 1px solid #D0D5DD;
      height: 1.5em;
      margin: 0 6px;
    }
  }
  #CustomReportWorkspaceContent {
    background-color: #D0D5DD;
    // padding: 40px 40px 100px 15vw;
    padding: 40px;
    .workspace-layout {
      border: 2px solid #F8F9FA;
      border-radius: 10px;
      // margin-right: 14.2vw;
      .react-grid-item.grid-item-card {
        background-color: white;
        border: 1px dashed #018FFC;
        border-radius: 10px;
        cursor: all-scroll;
        .item-card-header {
          .ant-image {
            cursor: pointer;
            position: absolute;
            top: 8px;
            right: 8px;
          }
        }
        .item-card-body {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        .react-resizable-handle {
          &::after {
            transform: scale(1.5) translateX(-1px) translateY(-1px);
          }
        }
        &.populated-card {
          border: 1px solid #DADADA;
        }
      }
    }
    .section-item {
      background-color: transparent !important;
      border: 2px solid transparent !important;
      .item-card-header {
        .ant-image {
          visibility: hidden;
        }
      }
      .ck {
        border: none;
        cursor: default;
      }
      .ck-editor__top {
        display: none;
      }
      .ck-editor__main > .ck-content {
        background-color: transparent;
      } 
      &:hover {
        border: 2px dashed #018FFC !important;
        .item-card-header {
          .ant-image {
            background-color: #FFFFFF !important;
            border-radius: 6px;
            visibility: visible;
          }
        }
      }
      [contenteditable] {
        outline: 0px solid transparent;
      }
    }
  }
  #ReportCoverImg {
    display: none;
  }
}

.workspace-selected-accounts-popover {
  .ant-popover-inner-content {
    padding: 15px !important;
    width: 500px;
    .ant-input {
      padding-left: 5px !important;
    }
    .ant-list-item {
      padding: 8px 0 !important;
      .ant-space-vertical {
        gap: 2px !important;
      }
    }
  }
}

.selected-accounts-platform-popover {
  .ant-popover-inner-content {
    padding: 10px !important;
  }
}

.custom-report-workspace-sidemenu {
  z-index: 1050 !important;
  .ant-drawer-close {
    display: none;
  }
  .ant-drawer-title {
    font-size: 20px;
  }
  .ant-drawer-body {
    padding: 0;
    .create-card-error-message {
      background-color: #FFD0C2;
      // font-size: 14px;
      padding: 3px 15px;
    }
    .blend-data-prompt-section {
      background-color: #F0F2F4;
      padding: 15px;
      .ant-switch-checked {
        background-color: #59BC7D;
      }
    }
    .card-configuration-section {
      height: 70vh;
      overflow-y: scroll;
      padding: 10px 20px 15px 20px;
      span.ant-typography {
        font-size: 14px;
        line-height: 2.5;
      }
    }
    .ant-radio {
      display: none;
    }
    .visualization-icon, .visualization-icon-selected {
      transform: translateY(7px);
    }
    .visualization {
      border: 1px solid #D0D5DD;
      border-radius: 6px;
      padding: 17px;
      .checked-visualization {
        position: absolute;
        top: 5px;
        right: 15px;
      }
      &:hover {
        border-color: #3c41CC;
        .checked-visualization {
          circle {
            stroke: #3C41CC;
          }
        }
        .visualization-icon {
          path {
            fill: #3C41CC;
          }
        }
      }
    }
    .visualization-selected {
      .visualization-icon-selected {
        path {
          fill: #3C41CC;
        }
      }
    }
    .ant-popover-disabled-compatible-wrapper {
      width: 100% !important;
    }
    .sidemenu-content-footer {
      bottom: 0;
      box-shadow: 5px 10px 8px 12px #888888;
      padding: 15px 20px 25px 20px;
      position: absolute;
      width: 100%;
    }
  }
}

.ant-picker-dropdown {
  z-index: 1100 !important;
}

.card-config-filter {
  z-index: 1090 !important;
}