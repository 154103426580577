@import '../../variable.less';

#DashboardMeta {
  #tabbedTablesWrapper {
    .ant-card-head {
      display: none;
    }
  }
  .empty-content-meta {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    height: 50vh;
    text-align: center;
    padding-top: 10vh;
    .bind-button {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  #MetaConversionFunnelChart, #MetaCollaborativeFunnelChart {
    .ant-card-head {
      padding: 0 16px;
    }
    #chart-bar-wrapper {
      .ant-card-head {
        .ant-typography {
          span {
            display: none;
          }
        }
      }
    }
    .highcharts-legend-item {
      display: none;
    }
  }
  
  #MetaConversionFunnelTable, #MetaCollaborativeFunnelTable {
    table {
      min-width: 0 !important;
      width: 100% !important;
    }
    .ant-pagination {
      display: none;
    }
  }

  .ant-tabs {
    .ant-card-extra {
      padding: 0;
    }
  }

  .ant-table-thead > tr > th {
    padding: 0 10px;
  }
}

