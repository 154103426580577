// @import '../../variable.less';

#EmailVerificationExpiredLayout {
  .ant-layout-header {
    background-color: #FFFFFF;
    height: 80px;
    text-align: center;
    .ant-image {
      margin-top: 5px;
    };
  }
  .ant-layout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 140px);
    #centeredContent {
      background-color: #FFFFFF;
      border:1px solid #D0D5DD;
      border-radius: 14px;
      height: 466px;
      width: 510px;
      #reenterEmailBtn {
        color: #018FFC;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    #centeredLoading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 466px;
    }
  }
  .ant-layout-footer {
    height: 40px;
  }
}