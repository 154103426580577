// @import '../../variable.less';

#CustomReportCardContent {
  padding: 10px;
  .ant-card-head {
    min-height: 32px;
  }
  .card-header-ellipsis-icon {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    z-index: 1100;
  }
  .loading-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  // chart styling adjustments
  .highcharts-legend-item {
    & span {
      border: 1px solid rgb(233, 233, 233);
      border-radius: 5px;
      box-shadow: 2px 7px 10px  #DADADA;
      & div {
        border: none !important;
        line-height: 0.9;
      }
    }
  }
  #chart-donut-wrapper {
    margin-right: 10px;
    // .chart-donut-left-col {
    //   & div {
    //     transform: translateY(-10px);
    //   }
    // }
    .chart-donut-right-col-custom-report {
      .ant-row {
        transform: translateY(20px);
      }
    }
    .chart-donut-see-detail-text {
      display: none;
    }
  }
  // stats styling adjustments
  #StatsListWrapper {
    border: none;
    .ant-card-head {
      border: none;
      padding-left: 18px !important;
      .ant-card-head-title {
        font-size: 16px;
        font-weight: 700;
      }
    }
    #StatsItemWrapper {
      padding: 0 10px;
      .ant-card-body {
        min-height: 100px;
        .stats-item-title {
          height: 20px;
        }
        .stats-item-title > span > span {
          font-size: 12px !important;
        }
        .stats-item-values {
          position: relative;
          height: 40px;
          div {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
  // table styling adjustments
  #tableWrapper {
    .ant-card-head {
      padding: 10px 16px 16px 16px;
      .ant-card-head-title {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .ant-card-body {
      // height: 270px;
      overflow-y: scroll;
      .ant-table-wrapper {
        // width: 90%;
        .ant-table-column-sorters {
          width: 95%;
        }
        .ant-table-cell {
          padding: 8px;
        }
        .ant-table-pagination {
          display: none;
        }
      }
    }
  }
  .card-description-section {
    position: absolute;
    bottom: 15px;
    left: 2.5%;
    width: 95%;
    .ant-input {
      background-color: #F8F9FA;
      font-size: 14px !important;
      height: 100px !important;
    }
  }
}

.card-header-ellipsis-popover {
  width: 200px;
  padding-top: 0 !important;
  .ant-popover-inner {
    border: 1px solid #DADADA;
    border-radius: 6px !important;
    padding: 10px;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        cursor: pointer;
        margin-bottom: 8px;
        .card-header-popover-menu-icon {
          transform: translateY(3px);
        }
      }
    }
  }
}