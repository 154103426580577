@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import '~react-animation/dist/keyframes.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import './theme.less';
@import './animations.less';
@import '~react-sliding-pane/dist/react-sliding-pane.css';
@import '~intro.js/introjs.css';
@import '/node_modules/react-grid-layout/css/styles.css';
@import '/node_modules/react-resizable/css/styles.css';

html {
  @media (min-width: 983px) {
    body {
      overflow-x: hidden;
    }
  }
}