@import '../../../variable.less';

#BudgetTrackerToolbar {
  .sorter-selection {
    .ant-select-focused.sorter-selection {
      border: none !important;
    }
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      padding: 0 7px;
    }
  }
}

#SavedBudgetTracks {
  .outer-table {
    th {
      font-size: 14px !important;
      font-weight: 600;
    }
    // .ant-table-body {
    //   overflow-x: hidden !important;
    // }
  }
  .inner-table {
    th {
      font-size: 14px !important;
      font-weight: 600;
      // text-align: center !important;
    }
  }
  .ant-progress-inner, .multicolor-progress-inner {
    border-radius: 6px;
    height: 25px;
    border: 1.5px solid #D0D5DD;
    .ant-progress-bg, .multicolor-progress-chunk-bg {
      border-radius: 0 !important;
      height: 25px !important;
    }
  }
  thead {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .ant-table-cell::before {
    width: 0 !important;
  }
  .ant-table-thead > tr > th {
    border-bottom: none !important;
    color: #7D8085 !important;
  }
  .ant-table-row, .ant-table-expanded-row > .ant-table-cell {
    background-color: #F8F9FA;
    .ant-table-thead, tr {
      border: none;
      border-color: #FFFFFF;
    }
    .ant-table-thead > tr > th, tr {
      border-bottom: none !important;
      background-color: #FFFFFF !important;
    }
  }
  #SetNewBudgetActionBtn {
    border: 1px solid #018FFC;
    color: #018FFC;
    font-weight: 550;
    &:hover {
      opacity: 0.75;
    }
  }
}

.remove-budget-modal {
  width: 390px;
  .ant-modal-footer {
    border: none;
    padding-bottom: 20px;
    text-align: center;
    .ant-btn {
      height: 50px;
      width: 200px;
    }
    .ant-btn-default {
      color: #7D8085;
    }
    .ant-btn-primary {
      background-color: #F04438;
      border: none;
      &:hover {
        opacity: 0.75;
      }
    }

  }
}

.create-budget-modal {
  .main-title {
    padding-top: 20px;
    text-align: left;
  }
  .panel-title {
    .ant-image {
      display: none;
    }
  }
  #BrandNamePanel {
    margin-bottom: 0;
    padding: 0;
    padding-top: 20px;
    #BrandNameTitle {
      display: none;
    }
    .brand-name-top-text {
      color: #999999;
    }
  }
  #AccountSelectionPanel {
    border: 1px solid #D0D5DD;
    padding-top: 0;
    #AccountSelectionTitle {
      font-size: 1.2rem;
      padding-top: 15px;
    }
  }
  #createBudgetBtn {
    margin: 0 !important;
  }
  .ant-modal-footer {
    display: none;
  }
}

.budget-indicator-dropdown {
  border-radius: 20px;
  li {
    padding: 5px;
  }
  .threshold {
    font-weight: 550;
    width: 70px !important;
  }
}
