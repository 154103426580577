@import '../../variable.less';

#ProfilePage {
  margin-bottom: 50px;
  .ant-form-item {
    margin-bottom: 10px;
  }
  label.ant-form-item-required::before {
    display: none;
  }
  .action-btn-wrapper {
    float: right;
    margin-right: 0;
    // position: absolute;
    // right: 40px;
    // bottom: 165px;
  }
  .action-btn {
    width: 120px;
  }
}

.avatar-wrapper {
  height: 0;

  .avatar-content {
    border-radius: 50px;
  
    width: 100px;
    height: 100px;
  
    background-color: #018FFC;
    border: 4px solid white;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    color: @bdd-white;
    font-size: 34px;
    margin-left: 30px;
  
    transform: scale(1.4) translateY(-90px);
  }
}

#profile-identity{
  .ant-card-body {
    background-color: #0065e1;
    border-radius: 10px;

    .row-profile {
      text-align: center;

      .avatar {
        border-radius: 50px;

        width: 100px;
        height: 100px;

        background-color: @bdd-blue;

        display: flex;
        justify-content: center;
        align-items: center;

        color: @bdd-white;
        font-size: 34px;
        margin: 25px auto 10px;
      }

      .info {
        margin: 8px 16px;

        h4.ant-typography {
          font-size: 20px;
          line-height: 32px;
          color: white;
        }
        
        span.ant-typography {
          color: white;
          font-size: 12px;
          position: relative;
          top: -8px;
        }
      }
    }
  }
}

#form-user {
  .ant-card-body {
    padding: 20px;
  }
}
