@import '../../../variable.less';

#persistentInfoBlockWrapper {
  margin-bottom: 25px;
  border-radius: 10px;
  border-style: solid;
  border-width: 5px;

  &.info {
    background-color: @bdd-blue-main-light;
    border-color: transparent;
    color: #FFFFFF;
    #closeBtn {
      svg {
        background-color: #FFFFFF !important;
        border-radius: 50%;
      }
    }
  }
  &.warning {
    background-color: @bdd-orange-main-light;
    border-color: @bdd-orange-main;
  }
  &.danger {
    background-color: @bdd-danger-var;
    border-color: @bdd-danger;
  }

  #infoHeader {
    padding: 0;
    text-align: right;

    #closeBtn {
      background-color: transparent;
      border: none;
      color: #000000 !important;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  #persistentInfoContainer {
    padding: 0 600px 10px 40px;

    .info-message {
      font-size: 1.3rem;
      font-weight: 550;
      margin-bottom: 10px;
    }
  }
}

#persistentInfoFloatWrapper {
  max-width: 40vw;
  position: fixed;
  bottom: 10px;
  z-index: 20;
  &.open {
    right: -1010px;
    -webkit-animation: slideIn 0.5s forwards;
    animation: slideIn 0.5s forwards;
    @-webkit-keyframes slideIn {
      100% { right: 10px; }
    }
    @keyframes slideIn {
      100% { right: 10px; }
    }
  }
  &.close {
    right: 10px;
    -webkit-animation: slideOut 0.5s forwards;
    animation: slideOut 0.5s forwards;
    @-webkit-keyframes slideOut {
      100% { right: -1010px; display: none; }
    }
    @keyframes slideOut {
      100% { right: -1010px; display: none; }
    }
  }

  border-radius: 10px;
  border-style: solid;
  border-width: 5px;

  &.info {
    background-color: #018FFC;
    border: none;
    color: #FFFFFF;
    #closeBtn {
      color: #018FFC;
    }
  }
  &.warning {
    background-color: @bdd-orange-main;
    border: none;
    #closeBtn {
      color: @bdd-orange-main;
    }
  }
  &.danger {
    background-color: @bdd-danger-var;
    border: none;
    #closeBtn {
      color: @bdd-danger-var;
    }
  }

  #persistentInfoContainer {
    padding: 30px;

    .info-message {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  #infoFooter {
    padding: 0;

    #closeBtn {
      background-color: #FFFFFF;
      border: none;
      font-weight: 550;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
