// @import '../../variable.less';

#RangePickerCreateReportWrapper {
  #RangePickerCreateReportBtn {
    text-align: left;
    span {
      margin-left: 0 !important;
    }
  }
}

.range-picker-create-report {
  z-index: 1070 !important;
  #rangePickerPopoverContent {
    width: 28.4vw;
    #applyButton {
      width: 100% !important;
    }
  }
}