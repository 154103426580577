// @import '../../variable.less';

#tableWrapper {
  height: 100%;
  thead.ant-table-thead {
    background-color: #F0F2F4 !important;
    tr > th {
      color: #9CA0A6 !important;
    }
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  .react-resizable-handle {
    background-image: none !important;
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  #AntdTable {
    th:nth-last-child(2)::before {
      display: none !important;
    }
    th:nth-last-child(2) {
      .react-resizable, .react-resizable-handle {
        display: none;
      }
    }
  }
}

#tabbedTablesWrapper {
  // height: 100%;
  thead.ant-table-thead {
    background-color: #F0F2F4 !important;
    tr > th {
      color: #9CA0A6 !important;
    }
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }
  .react-resizable-handle {
    background-image: none !important;
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  .ant-table-within-tab {
    th:nth-last-child(1) {
      .ant-table-column-sorter {
        display: none !important;
      }
    }
    th:nth-last-child(2)::before {
      display: none !important;
    }
    th:nth-last-child(2) {
      .react-resizable, .react-resizable-handle {
        display: none;
      }
    }
  }
}

.ant-tabs-nav-wrap {
  .ant-typography {
    color: #9CA0A6;
    font-weight: 500;
  }
  margin-left: 20px;
  .ant-tabs-tab-active {
    .ant-typography {
      color: #425ccf;
    }
  }
}
.ant-card-body {
  padding: 0 !important;
  // .ant-table-pagination.ant-pagination {
  //   padding-right: 40%;
  //   text-align: center !important;
  // }
}

#TableFilter {
  width: 260px;
  padding: 15px;
  .ant-card-head {
    padding: 0 !important;
    height: 20px;
    .ant-card-head-title {
      font-size: 16px;
      font-weight: 700;
    }
  }
  // .ant-typography {
  //   font-size: 1.1rem;
  //   font-weight: 500;
  // }
  .ant-checkbox-group {
    height: 250px;
    margin-top: 10px;
    overflow-y: scroll;
    width: 100%;
  }
  .ant-space-item, .ant-checkbox-wrapper {
    font-size: 14px;
  }
}
