// @import '../../variable.less';

#CustomReportPublic {
  min-height: 50vh;
  .report-header {
    background-color: #F8F9FA;
    text-align: center;
    padding: 10px 20px 20px 20px;
    background-color: #FFFFFF;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .loading-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .content-container {
    background-color: #D0D5DD;
    padding: 20px 40px;
    .grid-item {
      // background-color: #FFFFFF;
      border-radius: 10px;
      .card-header-ellipsis-icon {
        display: none;
      }
    }
  }
}