@import '../../variable.less';

#DashboardGoogleAds {
  #tabbedTablesWrapper {
    .ant-card-head {
      display: none;
    }
  }
  .empty-content-google-ads {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    height: 50vh;
    text-align: center;
    padding-top: 10vh;
    .bind-button {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  .ant-table-thead > tr > th {
    padding: 0 10px;
  }
}