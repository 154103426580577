@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?23015335');
  src: url('../font/fontello.eot?23015335#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?23015335') format('woff2'),
       url('../font/fontello.woff?23015335') format('woff'),
       url('../font/fontello.ttf?23015335') format('truetype'),
       url('../font/fontello.svg?23015335#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?23015335#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-calendar:before { content: '\e800'; } /* '' */
.icon-home:before { content: '\e801'; } /* '' */
.icon-left-open:before { content: '\e802'; } /* '' */
.icon-cart:before { content: '\e803'; } /* '' */
.icon-facebook:before { content: '\e804'; } /* '' */
.icon-google-white:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-custom-report:before { content: '\e807'; } /* '' */
.icon-search:before { content: '\e808'; } /* '' */
.icon-delete:before { content: '\e809'; } /* '' */
.icon-down:before { content: '\e80a'; } /* '' */
.icon-wallet:before { content: '\e80b'; } /* '' */
.icon-marketplace:before { content: '\e80c'; } /* '' */
.icon-shopee:before { content: '\e80d'; } /* '' */
.icon-time:before { content: '\e80e'; } /* '' */
.icon-google:before { content: '\e80f'; } /* '' */
.icon-google-ads:before { content: '\e810'; } /* '' */
.icon-google-analytics:before { content: '\e811'; } /* '' */
.icon-invoice:before { content: '\e812'; } /* '' */
.icon-gear:before { content: '\e813'; } /* '' */
.icon-instagram:before { content: '\e814'; } /* '' */
.icon-right:before { content: '\e815'; } /* '' */
.icon-menu:before { content: '\e816'; } /* '' */
.icon-milestone:before { content: '\e817'; } /* '' */
.icon-pencil:before { content: '\e818'; } /* '' */
.icon-prestashop:before { content: '\e819'; } /* '' */
.icon-sent:before { content: '\e81a'; } /* '' */
.icon-popup:before { content: '\e81b'; } /* '' */
.icon-shopify:before { content: '\e81c'; } /* '' */
.icon-success:before { content: '\e81d'; } /* '' */
.icon-tiktok:before { content: '\e81e'; } /* '' */
.icon-tokopedia:before { content: '\e81f'; } /* '' */
.icon-up-trend:before { content: '\e820'; } /* '' */
.icon-left:before { content: '\e821'; } /* '' */
.icon-website:before { content: '\e822'; } /* '' */
.icon-woocommerce:before { content: '\e823'; } /* '' */
.icon-analysis:before { content: '\e824'; } /* '' */
.icon-binding:before { content: '\e825'; } /* '' */
.icon-close:before { content: '\e826'; } /* '' */
.icon-clock:before { content: '\e82a'; } /* '' */
.icon-visitor:before { content: '\e831'; } /* '' */
.icon-logout:before { content: '\e832'; } /* '' */
.icon-filter:before { content: '\f0b0'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
