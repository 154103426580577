@import '../../../../variable.less';

#dashboard-side-menu {
  width: 280px;
  height: 100%;
  background-color: @bdd-blue-main !important;
  font-weight: 550;

  overflow: scroll;
  position: fixed;

  .group-title {
    color: #e0e0e0;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.4rem;
    padding-left: 1vw;
  }

  #sideMenuBottom {
    width: 250px;
    // ul.ant-menu {
    //   padding-bottom: 25px;
    // }
  }

  @media (max-device-width: 480px) {
    margin: 0px;
    box-shadow: none;
    width: 324px !important;
    max-width: 324px !important;

    max-height: 100vh;
    height: 100vh;
  }

  background-color: transparent;
  border-right: 1px solid #EAEAEA;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-title {
    padding: 20px 25px 20px 25px;
    display: flex;
    align-items: center;

    @media (max-device-width: 480px) {
      padding: 16px;
      padding-bottom: 20px;

      .ant-image {
        width: 60%;
      }

      .close-sidebar {
        position: absolute;
        right: 12px;
      }
    }

    .bdd-logo {
      height: 100%;
      padding: 0 20px 0;
    }

    span {
      font-size: @font-size-20;
      line-height: @font-size-24;

      font-weight: 600;

      color: @bdd-black;
    }
  }

  .ant-menu {
    border: none;
    background-color: transparent;
    color: @bdd-black;

    padding: 0px 16px;
    padding-bottom: 25px;
    font-size: 14px;

    .ant-menu-sub {
      padding: 0px;
      background-color: transparent;

      margin-left: 20px;
      margin-top: -8px;
      border-left: solid 1px @bdd-gray;

      .ant-menu-item {
        margin-left: 5px;
        width: calc(100% - 20px);
        height: 35px;

        .ant-menu-submenu-arrow {
          color: #e0e0e0 !important;
          margin-top: -2px;
          transform: rotate(-90deg);
        }
      }
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-selected{
        .ant-menu-submenu-title {
          background-color: @bdd-blue-main;
          color: @bdd-white;
          path {
            fill: @bdd-white;
          }
          font-weight: 600;

          &:hover {
            color: @bdd-white;
            path {
              fill: @bdd-white;
            }
          }

          .ant-menu-submenu-arrow {
            color: @bdd-white !important;
            path {
              fill: @bdd-white;
            }
          }
        }

        .ant-menu-sub {
          border-left: solid 1px @bdd-blue-main;
        }
      }


      .ant-menu-submenu-title {
        background-color: transparent;
        color: #e0e0e0;

        display: flex;
        align-items: center;

        height: 35px;
        margin: 5px 0px;
        border-radius: 6px;
        padding-left: 0px !important;
        &:hover {
          color: #efefef;
          path {
            fill: @bdd-white;
          }
        }

        .ant-menu-submenu-arrow {
          color: #e0e0e0 !important;
        }

        .icon-marketplace,
        .icon-campaign,
        .icon-gear,
        .icon-binding {
          width: 20px;
          margin-right: 14px;
        }
      }

      &.ant-menu-submenu-inline {
        .ant-menu-inline {
          border: unset;
        }
        .ant-menu-item {
          padding-left: 14px !important;
        }
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;

      background-color: transparent;
      color: #e0e0e0;

      height: 35px;
      margin: 8px 0px;
      border-radius: 6px;
      padding-left: 0 !important;
      &:hover {
        color: #efefef;
        path {
          fill: @bdd-white;
        }
      }

      .ant-menu-submenu-arrow {
        color: @bdd-white !important;
        path {
          fill: #d0d0d0;
        }
      }

      &.ant-menu-item-selected {
        background-color: @bdd-blue-main;
        color: @bdd-white;
        path {
          fill: @bdd-white;
        }

        font-weight: 800;

        .ant-menu-submenu-arrow {
          color: @bdd-white !important;
          path {
            fill: @bdd-white;
          }
        }
      }
    }

    .anticon-question-circle {
      margin-left: 7px;
      transform: scale(1.3);
    }
    .anticon-api {
      margin-left: 6px;
    }
    .anticon-user {
      margin-left: 6px;
    }

  }

  .separator-hl {
    border: 1px solid @bdd-border-thin-gray;
  }

  .section-binding{
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: transparent;
    box-shadow: 0px -5px 15px -10px #c1c1c1;

    .ant-menu{
      padding-bottom: unset;

      .ant-menu-submenu {
        &.ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            background-color: @bdd-blue-main;
            color: #e0e0e0;
            path {
              fill: @bdd-white;
            }
            font-weight: 800;

            &:hover {
              color: #efefef;
              path {
                fill: @bdd-white;
              }
            }

            .ant-menu-submenu-arrow {
              color: @bdd-white !important;
            }
          }

          .ant-menu-sub {
            border-left: solid 1px @bdd-blue-main;
          }
        }

        .ant-menu-submenu-title {
          background-color: transparent;
          color: #d0d0d0;
          path {
            fill: #d0d0d0;
          }

          display: flex;
          align-items: center;

          height: 35px;
          margin: 5px 0px;
          border-radius: 6px;
          padding-left: 16px !important;
          &:hover {
            color: #efefef;
            path {
              fill: @bdd-white;
            }
          }

          .ant-menu-submenu-arrow {
            color: #e0e0e0 !important;
          }

          .icon-gear,
          .icon-binding {
            width: 20px;
            margin-right: 14px;
          }
        }
      }
    }
  }
}

.ant-menu-submenu-popup {
  font-weight: 550;
  .ant-menu-item-selected {
    background-color: @bdd-blue-main !important;
    color: @bdd-white;
    font-weight: 800;
  }
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.no-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


#menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;

  .menu-icon {
    width: 20px;
    font-size: 20px;
    margin-right: 12px;
    margin-top: 0;
  }

  p {
    margin: 0;
  }
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: unset !important;
}
