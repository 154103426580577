@import '../../../variable.less';

#dashboard-layout {
  min-height: 100vh;
  padding-left: 250px;
  background-color: @bdd-background-lightgray4;
  width: 100%;
  overflow-x: hidden;

  .ant-layout-header {
    h1.ant-typography {
      font-size: 1.5rem;
    }
  }

  .content-header{
    display: inline-flex;

    .content-selection-button {
      margin-top: 7px;
      margin-left: 10px;
    }
  }

  #syncDataButton {
    background-color: #018FFC;
    border: none;
    border-radius: 10px;
    &:hover {
      opacity: 0.85;
    }
  }

  @media (max-device-width: 480px) {
    padding-left: 0px;
  }

  .ant-layout-header {
    z-index: 1000;
    background-color: @bdd-background-lightgray3;
    border-bottom: 2px solid @bdd-border-gray;
    padding: 25px 30px 20px 50px !important;

    width: calc(100% - 250px);
    height: 90px !important;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;

    .section-left-placeholder {
      display: flex;
      flex-direction: column;
      .greetings {
        height: 49px;
        width: 197px;
        border-radius: 6px;
      }
      .title {
        width: 240px;
        height: 32px;
        border-radius: 6px;

        margin-top: 8px;
      }
    }
    .section-right-placeholder {
      display: flex;
      flex-direction: column;

      align-items: flex-end;
      .account-section {
        display: flex;
        align-items: center;
        cursor: pointer;

        height: 40px;
        background-color: @bdd-white;

        padding-left: 12px;
        padding-right: 4px;

        margin-left: 10px;

        border-radius: 8px;

        .account {
          width: 91px;
          height: 21px;
          border-radius: 4px;
          margin-right: 20px;
        }

        .avatar {
          height: 32px;
          width: 32px;
          border-radius: 6px;
        }
      }

      .period {
        height: 32px;
        width: 150px;
        border-radius: 6px;
        margin-top: 16px;
      }
    }

    .header-right-section {
      display: flex;
      flex-direction: column;

      .header-right-section-top {
        display: inline-flex;
        margin-bottom: 16px;
        justify-content: flex-end;

        .btn-sync-data {
          border-radius: 10px;
          background-color: @bdd-blue-main;
          color: @bdd-white;
          font-size: @font-size-14;
          height: 40px;
          margin-right: 10px;
          text-align: center;
          width: fit-content;
        }

        .btn-selection-account {
          color: @bdd-primary;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          border: 2px solid @bdd-border-gray-medium;
          margin-left: 10px;

          i {
            color: @bdd-blue-main;
          }

          .anticon-contacts {
            svg {
              fill: @bdd-blue-main;
              transform: scale(1.3, 1.5);
            }
          }
        }

        .account-section {
          display: flex;
          align-items: center;
          cursor: pointer;

          height: 40px;
          background-color: @bdd-white;

          padding-left: 12px;
          padding-right: 4px;

          margin-left: 10px;

          border: 2px solid @bdd-border-gray-medium;
          border-radius: 8px;

          .account-name {
            width: max-content;
            margin-right: 20px;

            font-size: @font-size-14;
            color: @bdd-black;
            font-weight: 600;
          }

          .ant-avatar-square {
            background-color: @bdd-blue;
          }
        }
      }

      .header-right-section-bottom {
        display: inline-flex;
        justify-content: flex-end;

        .ant-select-selector {
          border-radius: 8px;
          border: none;

          width: 180px;

          font-size: @font-size-14;
        }
      }
    }
  }

  #platformPageEmptyState {
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    height: 50vh;
    text-align: center;
    padding-top: 10vh;
    .bind-button {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  @media (max-device-width: 480px) {
    width: 100%;
    padding: 0px;
    margin: 0px;
    position: relative;

    .header-mobile {
      position: fixed;
      z-index: 1;
      width: 100%;
      height: fit-content;
      background-color: @bdd-primary;
      border-radius: 0 0 15px 15px;

      display: flex;
      flex-direction: column;

      .header-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;

        .header-menu {
          display: flex;
          align-items: center;
          flex: 0;
        }

        .header-logo {
          margin-left: 10px;
        }

        .header-profile {
          display: flex;
          flex-direction: row;
          margin-left: 10px;
          .ant-avatar-square {
            background-color: @bdd-secondary;
            width: 30px;
            height: 30px;
            font-size: 14px;
          }
        }
      }

      .options-menus {
        display: flex;
        justify-content: space-between;
        padding: 0 15px 10px;
      }

      .btn-selection-account {
        color: @bdd-primary;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        margin-left: 10px;
        font-size: 14px;
        i {
          font-size: 14px;
        }
      }

      .header-action-nav {
        display: flex;
        justify-content: flex-end;
        padding: unset;
        text-align: right;
      }

    }
  }

  .ant-layout-content {
    margin-top: 120px !important;
    overflow-y: hidden;
    padding: 0px 50px;

    @media (max-device-width: 480px) {
      margin-top: 120px !important;
      overflow-y: hidden;
      padding: 0px 16px;
    }
  }
}

@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #dashboard-layout {

    width: 100vw;
    padding: 0px;
    margin: 0px;
    background-color: @bdd-background-lightgray4;
    position: relative;

    .header-mobile {
      position: fixed;
      z-index: 1;
      width: 100%;
      height: fit-content;
      background-color: @bdd-primary;
      border-radius: 0 0 15px 15px;

      display: flex;
      flex-direction: column;

      .header-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;

        .header-menu {
          display: flex;
          align-items: center;
          flex: 0;
        }

        .header-logo {
          margin-left: 10px;
        }

        .header-profile {
          display: flex;
          flex-direction: row;
          margin-left: 10px;
          .ant-avatar-square {
            background-color: @bdd-secondary;
          }
        }
      }

      .options-menus {
        display: flex;
        justify-content: flex-end;
        padding: 0 15px 10px;
      }

      .btn-selection-account {
        color: @bdd-primary;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        margin-left: 10px;
        font-size: 14px;
      }

      .header-action-nav {
        display: flex;
        justify-content: flex-end;
        padding: unset;
        text-align: right;
      }

    }

    .ant-layout-content {
      margin-top: 120px !important;
      padding: 0px 50px;
    }
  }
}

#NotificationListPopup {
  border: unset;

  .ant-list-items {
    max-height: 90vh;
    overflow-y: auto;
    border-top: 1px solid #D0D5DD;
    padding: 0;
    .ant-list-item {
      border-radius: 0;
    }
    .ant-list-item:hover {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
}

