// @import '../../variable.less';

.account-box-menu-item:hover {
  color: #1F36C1;
}

#accountBox {
  .content {
    display: flex;
    align-items: center;

    .user-info {
      display: grid;
      margin-left: 5px;
      margin-right: 15px;
      justify-items: start;

      .ant-typography {
        margin: unset !important;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
