@import '../../../variable.less';

.toolbar-button {
  border: none;
  margin-left: 20px;

  &.resync-button {
    background-color: #3C41CC;
    color: #FFFFFF;
    &:hover {
      opacity: 0.85;
    }
  }
  &.resync-button:hover {
    background-color: #7578cb;
    color: #3C41CC;
    &:hover {
      opacity: 0.85;
    }
  }

  &.remove-button {
    background-color: #F04438;
    color: #FFFFFF;
    &:hover {
      opacity: 0.85;
    }
  }
  &.remove-button:hover {
    background-color: #FAC5C1;
    color: #F04438;
    border-color: #F04438;
    &:hover {
      opacity: 0.85;
    }
  }
}
