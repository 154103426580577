@import '../../../variable.less';

#CreateReportConfigForm {
  background-color: #FFFFFF;
  border: 1px solid #BABABA;
  border-radius: 10px;
  font-size: 14px;
  padding: 30px 30px 45px 30px;

  .ant-btn {
    font-size: 14px;
  }

  .form-field-name {
    margin-top: 15px;
  }

  .ant-radio {
    display: none;
  }

  .document-type, .document-type-selected {
    border: 1px solid #D0D5DD;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    width: 230px;
    .checked-document-type {
      position: absolute;
      top: 10px;
      right: 20px;
      transform: scale(1.1);
    }
  }
  .document-type {
    &:hover {
      border-color: #3C41CC;
      circle, path {
        stroke: #3C41CC;
      }
      path {
        fill: #3C41CC;
      }
    }
  }
  .document-type-selected {
    border-color: #3C41CC;
    .document-type-icon, .document-type-icon-selected {
      path {
        stroke: #3C41CC;
        fill: #3C41CC;
      }
    }
  }
  .document-type-copytext {
    margin-top: 10px;
    text-align: center;
    .document-type-title {
      color: #222222;
      margin-bottom: 0 !important;
    }
    .document-type-subtitle {
      color: #6B6B6B;
      font-size: 12px;
    }
  }

  #CreateReportBtn {
    font-size: 16px;
    width: 100%;
  }
}

.create-report-date-range-picker {
  z-index: 1070 !important;
  .ant-popover-inner {
    padding: 15px;
  }
}