#DashboardGoogleAnalytics {
  #chart-map-wrapper {
    .title-daterange-current, .title-daterange-compare {
      display: none;
    }
    .ant-segmented {
      position: absolute;
      top: 70px;
      right: 25px;
    }
    .ant-card-extra {
      z-index: 100;
    }
    .ant-card-body {
      min-height: 100px;
    }
  }
  #chart-donut-wrapper, #chart-bar-wrapper {
    .title-daterange-current, .title-daterange-compare {
      display: none;
    }
    #StatsListWrapper {
      .ant-collapse {
        display: none;
      }
    }
  }
  .chart-gender-age-tabs {
    width: 100%;
    .ant-tabs-nav {
      margin: 0;
    }
  }
  .chart-donut-device {
    #chart-donut-wrapper > .ant-card-body {
      margin-top: 45px;
    }
  }
  #tabbedTablesWrapper {
    .ant-card-head {
      display: none;
    }
  }
  #userPurchaseJourneyPanel {
    .ant-table-column-sorter {
      visibility: hidden;
    }
    #TopTable {
      .ant-table-tbody {
        background-color: #FFFFFF !important;
        .ant-table-row, .ant-table-cell {
          background-color: #FFFFFF !important;
        };
      }
      td {
        padding: 0 !important;
      }
      .ant-table-cell {
        padding: 0 1px !important;
        overflow: hidden;
      }
      #chart-bar-wrapper {
        padding-bottom: 0;
        transform: translateY(12px);
      }
    }
    #tableWrapper {
      margin-bottom: 0;
    }
    .ant-table-pagination {
      display: none;
    }
    .ant-card-head {
      display: none;
    }
    .bottom-table-collapsed {
      .ant-table-tbody {
        display: none;
      }
    }
  }
}


#googleAnalytics{
  .ant-tabs-tab{
    font-size: 14px;
  }
  .card-doutgnut{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .card-content{
  }

  #content-view{
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-content-holder{
      padding: unset;
      min-height: unset;
    }
  
    .ant-tabs-nav-list {
      margin: 5px 5px 5px 2px;
    }
  
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      margin-right: 10px;
  
      padding: 0px 10px 0px 10px;
      height: 40px;
  
    }
  
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
      border: 1px solid #f0f0f0;
      border-radius: 50px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 8%);
  
      padding: 0px 10px 0px 10px;
      height: 40px;
  
    }
    
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1F36C1;
    }
  
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      background: none;
      border: none;
    }
  
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0;
    }
  }

}

@media only screen
and (orientation: portrait)
and(max-device-width: 480px) {
  #googleAnalytics {
    .summary-overview {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .summary-overview-sec {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .chart-section {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
  }
}
@media only screen
and (orientation: landscape)
and (max-device-width: 950px) {
  #googleAnalytics {
    .summary-overview {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .summary-overview-sec {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
    .chart-section {
      .ant-row {
        display: flex;
        flex-flow: nowrap;
        overflow: auto;
      }
    }
  }
}