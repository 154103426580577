@import '../../variable.less';

#StatsListWrapper {
  border: 1px solid #D0D5DD;
  height: 100%;

  .ant-card-head {
    padding: 5px 10px !important;
    #StatsItemWrapper {
      .stats-item-title {
        color: #5E6063;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
        #SeeDetailButton {
          float: right;
        }
      }
      .current-value {
        font-size: 24px;
        font-weight: 700;
      }
    }
    #expandButton {
      border: 1px solid #018FFC;
      border-radius: 6px;
      color: #018FFC;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  .ant-card-body {
    padding: 10px 10px 10px 10px !important;
    #StatsItemWrapper {
      margin: 5px;
      padding: 5px 20px;
      height: 100%;
      // max-width: 300px;
      .ant-card-body {
        padding: 0px !important;
      }
      &:hover {
        background-color: #F0F2F4;
      }
      .stats-item-title {
        color: #5E6063;
        font-size: 14px;
        padding-bottom: 5px;

      }
      .current-value {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.stats-list-drawer {
  .ant-drawer-header {
    background-color: #F8F9FA;
    border: none !important;
    min-height: 60px;
    #closeBtn {
      color: #000000;
      &:hover {
        opacity: 0.85;
      }
    }
  }
  .ant-drawer-body {
    background-color: #F8F9FA;
    padding: 0 !important;
    .ant-card-head {
      border: none !important;
    }
    #StatsListWrapper {
      background-color: #F8F9FA;
      border: none;
      // padding: 0 15vw;
      // padding: 0 24px;
      position: absolute;
      // left: 15vw;
      height: fit-content;
  
      #StatsListTitle {
        border: 1px solid #D0D5DD;
        border-radius: 10px;
        padding: 15px 30px;
        padding-top: 0;
        .spc-nocompare {
          gap: 0 !important;
        }
        .current-stats {
          text-align: right;
        }
        .ant-avatar {
          span {
            color: #000000;
            font-weight: 700;
          }
        }
        .previous-stats {
          text-align: left;
        }
        .line-text {
          color: #9CA0A6;
        }
        .stats-value {
          font-size: 1.1rem;
          font-weight: 700;
        }
        .stats-date-range {
          color: #7D8085;
        }
      }
      .ant-row {
        .ant-col {
          #StatsItemWrapper {
            &:hover {
              background-color: #F0F2F4;
            }
            // min-height: 100px;
            border: 1px solid #D0D5DD;
            border-radius: 10px;
          }
        }
      }
    }
    #copyButton {
      background-color: #018FFC;
      border: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }
}

.ant-collapse-item-active > .ant-collapse-header {
  display: none !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
