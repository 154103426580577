@import '../../../variable.less';

.multicolor-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}

.multicolor-progress-line {
  position: relative;
  width: 100%;
  font-size: 16px;
}

.multicolor-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}

.multicolor-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}